import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Sidebar from '../components/Sidebar';
import ContentArea from '../components/ContentArea';

class App extends React.Component {
  constructor(props) {
    super();
    let s = {}
    if(props && props.settings) s["settings"] = props.settings
      
    this.state = s;
  }
  render() {
    return(
      <div className="iq-root-container">
        <Sidebar 
          settings = {this.state.settings}
        />
        <ContentArea />
      </div>
    );  
  }
}

export default App;
