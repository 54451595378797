import React from 'react';
import ReactDOM from 'react-dom';
import * as Cookies from '../../helpers/Cookies';

const Logout = props => {
  //Clear cookies
  //redirect to home
  Cookies.eraseCookie('_piq-session');
  window.location.href = "/";
  return null;
}

export default Logout;
