import React, { Component } from "react";

const Audits = props => {
  return (
    <div>
      <h1>Security Audits</h1>
    </div>
  );
};

export default Audits;
