import React, { Component } from "react";

const Settings = props => {
  return (
    <div>
      <h1>Settings</h1>
    </div>
  );
};

export default Settings;
