import React, { Component } from "react";
import Table from '../../app/Table';
import ApiClient from '../../../app/ApiClient';

class Assets extends React.Component {
  constructor(props) {
    super();      
    this.state = {
      loading: true
    };

    let api = new ApiClient(window.app.apiUrl);
    api.client.get("/assets", {data: null})
      .then(response => {
        this.setState({
          loading: false
        });
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  onTableRowClick(e){
    e.preventDefault();
    console.log("here..")
  }
  render() {
    return(
      <div>
        <h1 className="margin-bottom-40">Assets</h1>
        <Table 
          onClick={this.onTableRowClick}
          loading={this.state.loading}
          data={this.state.assets}
        />
      </div>
    );  
  }
}


export default Assets;
