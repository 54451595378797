import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  errors: PropTypes.string
};

const FormErrors = ({title, errors}) => {
  if (errors) {
    return (
      <div className="form-errors notification__error">
        { title && <h5 className="u-flush--bottom">{title}</h5> }
        <span>{errors}</span>
      </div>
    );
  } else {
    return <div/>;
  }
};

FormErrors.propTypes = propTypes;

export default FormErrors;
