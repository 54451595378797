import React, { Component } from "react";

/* Generic table component
*
* @props.onClick function (optional): will turn all table rows into clickable rows and will execute the function onClick
*/
const Table = props => {
  let hasLink = false;
  let loading = false;
  if(props && props.onClick) hasLink = true;
  if(props && props.loading) loading = props.loading;
  
  const renderLoader = () => {
    return (
      <div>
        <div className="loader-centered margin-top-50p" />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <div>
        <table className="iq-table">
          <thead>
            <tr>
              <th>
                <div>ID</div>
              </th>
              <th>
                <div>Description</div>
              </th>
              <th>
                <div>Type</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={hasLink ? "iq-table-row__has-link" : "" } onClick={props.onClick}>
              <td>
                <div>1</div>
              </td>
              <td>
                <div>Gotham App</div>
              </td>
              <td>
                <div>Web App &amp; API</div>
              </td>
            </tr>
            <tr className={hasLink ? "iq-table-row__has-link" : "" } onClick={props.onClick}>
              <td>
                <div>2</div>
              </td>
              <td>
                <div>Web Server (Staging)</div>
              </td>
              <td>
                <div>Network</div>
              </td>
            </tr>
          </tbody>
        </table>
        { renderPagination() }
      </div>
    );
  };

  const renderPagination = () => {
    return(
      <div className="iq-table-pagination">
        <div className="iq-table-pagination__count">
          <b>10</b> Results
        </div>
        <div className="iq-table-pagination__controls">
          <ul>
            <li><a href="#">Previous</a></li> 
            <li><a href="#">Next</a></li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="table-wrapper">
      { loading ? renderLoader() : renderTable() }
    </div>
  );
};

export default Table;
