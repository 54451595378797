import axios from 'axios';
import * as Cookies from '../helpers/Cookies';

/* ApiClient is a tiny wrapper around axios, so to avoid having to setup default config multiple times
*
*/
class ApiClient {
  constructor(apiUrl) {
    this.client = axios.create({
      baseURL: apiUrl,
      timeout: 5000,
      headers: {
        'Accept-Version': 1,
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8',
      }
    });

    // If there is a session cookie, we will set the token
    let session = Cookies.readCookie('_piq-session');
    if(session){
      session = JSON.parse(session);
      this.setToken(session.token, session.client, session.uid);
    }
  }
  setToken(token, client, uid){
    this.client.defaults.headers.common["access-token"] = token;
    this.client.defaults.headers.common["client"] = client;
    this.client.defaults.headers.common["uid"] = uid;
  }
}

export default ApiClient;
