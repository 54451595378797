import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import IQMarkIMG from 'images/logo-iq-mark.svg';
import TinyGreyArrowIMG from 'images/icons/tiny-grey-arrow-right.svg';
import FowardSlashIMG from 'images/slash.svg';

class Sidebar extends React.Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return(
      <div className="iq-sidebar">
        <div className="iq-logo-nav">
          <Link className="iq-logo-nav__logo-link" to="/">
            <img src={IQMarkIMG} alt="PentestIQ" />
          </Link>
          <div className="iq-logo-nav__slash">
            <img src={FowardSlashIMG} alt="/" />
          </div>
          <div className="iq-logo-nav__org">
            {this.props.settings.user.org_profile.name}
          </div>
        </div>
        <ul className="iq-nav">
          <li>
            <div className="iq-nav__arrow"><img src={TinyGreyArrowIMG} alt=">"/></div>
            <div className="iq-nav__label">
              <Link className="iq-nave_link" to="/assets">Assets</Link>
            </div>
          </li>
          <li>
            <div className="iq-nav__arrow"><img src={TinyGreyArrowIMG} alt=">"/></div>
            <div className="iq-nav__label">
              <Link className="iq-nave_link" to="/audits">Security Audits</Link>
            </div>
          </li>
          <li>
            <div className="iq-nav__arrow"><img src={TinyGreyArrowIMG} alt=">"/></div>
            <div className="iq-nav__label">
              <Link className="iq-nave_link" to="/team">Team</Link>
            </div>
          </li>
          <li>
            <div className="iq-nav__arrow"><img src={TinyGreyArrowIMG} alt=">"/></div>
            <div className="iq-nav__label">
              <Link className="iq-nave_link" to="/settings">Settings</Link>
            </div>
          </li>
        </ul>
      </div>
    );  
  }
}

export default Sidebar;
