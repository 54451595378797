import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import ArrowDownIcon from 'images/icons/tiny-grey-arrow-down.svg';

class AppHeader extends React.Component {
  state = {
    menuOpen: false,
  };

  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen
    }));
  };

  hideMenu = (e) => {
    //we don't want to prevent default on event so that the links work
    this.setState({
      menuOpen: false
    });
  };

  render() {
    const { menuOpen } = this.state;
    
    return(
      <div className="iq-app-header">
        <div className="iq-global-search">
          <form>
            <input className="iq-smaller-input" type="text" placeholder="search" />
          </form>
        </div>
        <div 
          className="iq-user-menu"
          onClick={this.toggleMenu}
        >
          <div className="iq-app-header_avatar">
            <img className="iq-avatar" src="https://pbs.twimg.com/profile_images/1160712867177537536/jvPy2ij3_400x400.jpg" />
          </div>
          <img className="iq-user-menu__arrow" src={ArrowDownIcon} alt="" />
        </div>
        {menuOpen && (
          <div className="iq-user-menu-popover">
            <ul className="iq-user-menu-nav">
              <li>
                <Link to="/help" onClick={this.hideMenu}>Help &amp; Support</Link>
              </li>
              <li>
                <Link to="/logout">Log out</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    );  
  }
}

export default AppHeader;
