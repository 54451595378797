import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import AppHeader from "../components/AppHeader";
import Routes from "../app/Routes";

class ContentArea extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }
  render() {
    const UIisLoading = this.state.loading;

    return(
      <div className="iq-content-area">
        <AppHeader />
        <div className="iq-content-area__content">
          {UIisLoading
            ? <div className="loader-centered margin-top-50p" />
            : 
              <Switch>
                {Routes.map(route => (
                  <Route key={route.path} {...route} />
                ))}
              </Switch>
          }
        </div>
      </div>
    );  
  }
}

export default ContentArea;
