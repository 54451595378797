import React from 'react';
import ReactDOM from 'react-dom';
import IQMarkIMG from 'images/logo-iq-mark.svg';
import ApiClient from '../../app/ApiClient';
import FormErrors from '../../components/app/FormErrors';
import * as Cookies from '../../helpers/Cookies';

class AuthPage extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.api = new ApiClient(window.app.apiUrl);
    this.handleChange = this.handleChange.bind(this); 
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(field, event) {
    let nextState = {};
    nextState[field] = event.target.value;
    this.setState(nextState);
  }
  handleSubmit(event) {
    event.preventDefault();
    if(this.state.email && this.state.password){
     
      this.api.client.post("/users/sign_in", {"user": this.state})
        .then(response => {
          console.log(response);
          const session = {
            token: response.headers["access-token"],
            uid: response.headers["uid"],
            client: response.headers["client"]
          };
          Cookies.setCookie('_piq-session', JSON.stringify(session), 14);
          window.location.href = "/";
        })
        .catch(error => {
          if(error.response.status === 401){
            this.handleError();
          }
        });

    }
  }
  handleError() {
    this.setState({
      errors: '░ This login is not valid.'
    });
  }
  render() {
    return(
      <div className="auth-page-container">
        <div className="auth-page__logo">
          <img src={IQMarkIMG} alt="PentestIQ" />
        </div>
        <div className="login-box">
          <FormErrors errors={this.state.errors} />
          <form onSubmit={e => this.handleSubmit(e)}>
            <div className="form-row__input-display-block">
              <input 
                autoFocus
                placeholder="Email"
                type="email" 
                name="email" 
                onChange={e => this.handleChange('email', e)} />
            </div>
            <div className="form-row__input-display-block">
              <input
                placeholder="Password"
                type="password" 
                name="password"
                onChange={e => this.handleChange('password', e)} />
            </div>
            <div className="form-row__centered">
              <input type="submit" name="commit" value="Log in" className="btn btn-width-100" data-disable-with="Log in" />
            </div>
          </form>
          <div className="margin-top-20 txt-align-center txt-small">
            <a href="#" style={{display: "none"}}>Forgot your Password?</a>
          </div>
          <div className="margin-top-40 txt-align-center txt-small">
            <span className="txt-grey-1">Looking to signup?</span> <a href="https://www.pentestiq.com/start/">Contact Sales</a>
          </div>
        </div>
      </div>

    );  
  }
}

export default AuthPage;
