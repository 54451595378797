import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import App from '../components/App';
import AuthPage from '../components/auth/AuthPage';
import { BrowserRouter } from "react-router-dom";

import * as Cookies from '../helpers/Cookies';
import ApiClient from '../app/ApiClient';


// Setting up the basics
let host = "http://localhost:7777"
if(process.env.NODE_ENV === "production") host = "https://app.pentestiq.com"


window.app = {
  'host': host,
  'apiUrl': host + "/api/v1",
}


// Demo of API call capabilities 
let api = new ApiClient(window.app.apiUrl);
api.client.get("/stats", {data: null})
  .then(response => {
    //console.log(response);
  })
  .catch(function (error) {
    //console.log(error);
  });




/*

1. Read Cookie
2. If no auth token in cookie show login view
3. GET call for settings
4. If get goes 401: show login view
5. If get goes 200: show dashboard

*/

let session = Cookies.readCookie('_piq-session');

function bootApp(element, settings) {
  // Render the app
  ReactDOM.render(
    <BrowserRouter>
      <App 
        settings = {settings}
      />
    </BrowserRouter>, 
    document.body.appendChild(element)
  );
}

function showAuthPage(element) {
  // Render the login page so user can auth
  ReactDOM.render(
    <BrowserRouter>
      <AuthPage />
    </BrowserRouter>, 
    document.body.appendChild(element)
  );
}

// Bootup App
document.addEventListener('DOMContentLoaded', () => {
  let element = document.createElement('div');
  element.setAttribute('class', 'root');

  if(session){
    session = JSON.parse(session);

    api.setToken(session.token, session.client, session.uid)

    // Fetch user settings
    api.client.get("/user/settings", {data: null})
    .then(response => {
      bootApp(element, response.data);
    })
    .catch(function (error) {
      console.log('Session not valid.');
      console.log(error);
      showAuthPage(element)
    });

  }else{
    showAuthPage(element);
  }
});
