import React, { Component } from "react";

const Help = props => {
  return (
    <div>
      <h1>Help &amp; Support</h1>
    </div>
  );
};

export default Help;
