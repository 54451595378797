// All Page components
import Home from '../components/pages/home';
import Assets from '../components/pages/assets';
import Settings from '../components/pages/settings';
import Audits from '../components/pages/audits';
import Help from '../components/pages/help';
import Logout from '../components/auth/Logout';


// All Routes
const Routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    requireLogin: true
  },
  {
    path: "/assets",
    exact: true,
    component: Assets,
    requireLogin: true
  },
  {
    path: "/audits",
    exact: true,
    component: Audits,
    requireLogin: true
  },
  {
    path: "/settings",
    exact: true,
    component: Settings,
    requireLogin: true
  },
  {
    path: "/help",
    exact: true,
    component: Help,
    requireLogin: true
  },
  {
    path: "/logout",
    exact: true,
    component: Logout,
    requireLogin: true
  }
];

export default Routes;
